





































































































































import SelectCompanyBank from "@/components/custom/select/SelectCompanyBank.vue";
import SelectLessor from "@/components/custom/select/SelectLessor.vue";
import SelectLeasing from "@/components/Leasing/SelectLeasing.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { setNumbering } from "@/helpers/common";
import { generateUUID } from "@/helpers/uuid";
import {
  APagination,
  useBlob,
  useDate,
  useFindMasterType,
  useMapMasterTypeToOptionAlt,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  LeasingPaymentPlanReportDto,
  LeasingPaymentPlanReportPageDto,
  LeasingPaymentPlanReportParams,
} from "@/models/interface/leasing-payment-plan";
import { leasingPaymentPlanService } from "@/services/leasing-payment-plan.service";
import { ColumnDef, LabelInValue, SorterProps } from "@/types";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import Vue from "vue";
import { mapState } from "vuex";

type Record = { key: string; no: number } & LeasingPaymentPlanReportDto;
type ReportData = Omit<LeasingPaymentPlanReportPageDto, "data"> & {
  data: Record[];
};

type FormState = {
  paymentDate: Moment[] | null;
  companyBank: LabelInValue | undefined;
  supplierType: string | undefined;
  lessor: LabelInValue | undefined;
  leasing: LabelInValue | undefined;
};

export default Vue.extend({
  name: "LeasingPaymentPlan",
  components: {
    SelectCompanyBank,
    SelectLessor,
    SelectLeasing,
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      PAGE_SIZE_OPTIONS,
      formRef: null as FormModel | null,
      supplierTypeOptions: [] as Option[],
      formState: {
        paymentDate: [],
        companyBank: undefined,
        supplierType: undefined,
        lessor: undefined,
        leasing: undefined,
      } as FormState,
      loading: {
        submit: false,
        download: false,
        supplierType: false,
      },
      pagination: {
        page: 1,
        limit: 10,
        sorts: "leasingNumber:asc" as string | undefined,
      },
      dtReport: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalInstallment: 0,
        totalInterest: 0,
        totalPages: 0,
        totalPrincipal: 0,
      } as ReportData,
      columns: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          width: 70,
        },
        {
          title: this.$t("lbl_lessor_name"),
          dataIndex: "lessorName",
          key: "lessorName",
          sorter: true,
          ellipsis: true,
          scopedSlots: { customRender: "lessorName" },
        },
        {
          title: this.$t("lbl_billing_period"),
          dataIndex: "installment",
          key: "installment",
          sorter: true,
          width: 150,
        },
        {
          title: this.$t("lbl_payment_date"),
          dataIndex: "paymentDate",
          key: "paymentDate",
          sorter: true,
          customRender: text => dateFormat(text),
        },
        {
          title: this.$t("lbl_leasing_contract_number"),
          dataIndex: "leasingContractNumber",
          key: "leasingContractNumber",
          width: 220,
          sorter: true,
        },
        {
          title: this.$t("lbl_leasing_number"),
          dataIndex: "leasingNumber",
          key: "leasingNumber",
          sorter: true,
        },
        {
          title: this.$t("lbl_bank_name"),
          dataIndex: "bankAccount",
          key: "bankAccount",
          sorter: true,
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          ellipsis: true,
          sorter: true,
          scopedSlots: { customRender: "unitCode" },
        },
        {
          title: this.$t("lbl_principal_cost"),
          dataIndex: "principalCost",
          key: "principalCost",
          sorter: true,
          align: "right",
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_interest_cost"),
          dataIndex: "interestCost",
          key: "interestCost",
          sorter: true,
          align: "right",
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_total"),
          dataIndex: "paymentCost",
          key: "paymentCost",
          sorter: true,
          align: "right",
          customRender: text => currencyFilter(text),
        },
      ] as ColumnDef[],
    };
  },
  computed: {
    ...mapState({
      authStore: (st: any) => st.authStore,
    }),
  },
  mounted() {
    this.getSupplierTypeList();
    if (this.$refs.formRef) {
      this.formRef = this.$refs.formRef as FormModel;
    }
  },
  methods: {
    getSupplierTypeList(): void {
      this.loading.supplierType = true;
      useFindMasterType("SUPPLIER_TYPE")
        .then(response => {
          const criteria = "LEASING";
          const options = response.filter(val => {
            return val.value.toUpperCase().includes(criteria);
          });
          this.supplierTypeOptions = useMapMasterTypeToOptionAlt(options);
        })
        .finally(() => {
          this.loading.supplierType = false;
        });
    },
    getReportList(params?: LeasingPaymentPlanReportParams): void {
      this.loading.submit = true;
      leasingPaymentPlanService
        .getList(params)
        .then(response => {
          this.dtReport = {
            ...response,
            data: response.data.map((item, index) => {
              return {
                ...item,
                no: setNumbering(params?.page, params?.limit, index),
                key: generateUUID(),
              };
            }),
          };
        })
        .finally(() => {
          this.loading.submit = false;
        });
    },
    handleReset(): void {
      this.formRef?.resetFields();
      this.pagination.page = 1;
      this.pagination.sorts = "leasingNumber:asc";
      const params: LeasingPaymentPlanReportParams = this.toParams(
        this.formState,
        this.pagination
      );
      this.getReportList(params);
    },
    handleSubmit(): void {
      this.pagination.page = 1;
      const params: LeasingPaymentPlanReportParams = this.toParams(
        this.formState,
        this.pagination
      );
      this.getReportList(params);
    },
    toParams(
      state: FormState,
      pagination: { page: number; sorts: string | undefined; limit: number }
    ): LeasingPaymentPlanReportParams {
      const { toStartDay, toEndDay } = useDate();
      let dateStart: string | undefined;
      let dateEnd: string | undefined;
      if (state.paymentDate && state.paymentDate.length > 0) {
        const [start, end] = state.paymentDate;
        dateStart = toStartDay(start).format();
        dateEnd = toEndDay(end).format();
      }

      const params: LeasingPaymentPlanReportParams = {
        bankId: state.companyBank?.key,
        dateFrom: dateStart,
        dateTo: dateEnd,
        leasingId: state.leasing?.key,
        lessorId: state.lessor?.key,
        limit: pagination.limit,
        page: pagination.page - 1,
        sorts: pagination.sorts,
        supplierType: state.supplierType,
      };

      return params;
    },
    toParamsDownload(
      state: FormState,
      pagination: { page: number; sorts: string | undefined; limit: number }
    ): LeasingPaymentPlanReportParams {
      const { toDefaultFormat } = useDate();
      const params = this.toParams(state, pagination);
      const company = this.authStore.authData.companyName ?? "ALL";
      const bankName = state.companyBank?.label ?? "ALL";
      const startDate = params.dateFrom
        ? toDefaultFormat(moment(params.dateFrom))
        : "ALL";
      const endDate = params.dateTo
        ? toDefaultFormat(moment(params.dateTo))
        : "ALL";
      const supplierType = state.supplierType ?? "ALL";
      const lessorName = state.lessor?.label ?? "ALL";
      const leasingNumber = state.leasing?.label ?? "ALL";

      const headers = [
        company,
        `${startDate} - ${endDate}`,
        bankName,
        supplierType,
        lessorName,
        leasingNumber,
      ];

      params.params = headers.join(",");
      return params;
    },
    onChangeTable(
      { current, pageSize }: APagination,
      _,
      { columnKey, order }: SorterProps
    ): void {
      this.pagination.page = pageSize !== this.pagination.limit ? 1 : current;
      this.pagination.limit = pageSize;
      if (order && order === "ascend") {
        this.pagination.sorts = `${columnKey}:asc`;
      } else if (order && order === "descend") {
        this.pagination.sorts = `${columnKey}:desc`;
      } else {
        this.pagination.sorts = undefined;
      }

      const params: LeasingPaymentPlanReportParams = this.toParams(
        this.formState,
        this.pagination
      );
      this.getReportList(params);
    },
    handleDownload(): void {
      const { toDownload } = useBlob();
      const params = this.toParamsDownload(this.formState, this.pagination);
      this.loading.download = true;
      leasingPaymentPlanService
        .download(params)
        .then(response => {
          toDownload(response, "leasing-payment-plan-report.xlsx");
        })
        .finally(() => {
          this.loading.download = false;
        });
    },
  },
});
